@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 235 169 0;
    --color-secondary: 186 12 47;
    --color-page-background: 242 242 242;
    --color-text: 34 34 34;
    --color-card-background: 255 255 255;
    --color-button-background: 235 169 0;
    --color-button-text: 0 0 0;
    --color-divider: 217 217 214;
    --color-success: 0 128 0;
    --color-error: 215 0 0;
    --color-button-hover-background: "transparent";
    --color-button-hover-text: 0 0 0;
  }

  .dark {
    --color-primary: 235 169 0;
    --color-page-background: 49 49 49;
    --color-text: 255 255 255;
    --color-card-background: 34 34 34;
    --color-button-background: 235 169 0;
    --color-button-text: 0 0 0;
    --color-divider: 133 135 138;
    --color-success: 0 230 6;
    --color-error: 255 0 0;
    --color-button-hover-background: "transparent";
    --color-button-hover-text: 255 255 255;
  }
}
